import styled from "styled-components";

export const BoardOperacionalContainer = styled.div`
  background-color: #ffff;
  width: 100%;
  height: 100%;
  min-height: 89vh;
  border-radius: 8px;
  padding: 20px 25px;
`;
